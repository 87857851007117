.contact-us {
    @apply flex space-x-4 ;

    > .form-detail {
        @apply flex-1;

        .title {
            @apply text-lg font-bold text-blue-700;
        }

        ._address {
            @apply my-4;
        }

        .contact-item {
            @apply block py-2 px-4 border border-grey-100 rounded-lg my-2 cursor-pointer hover:border-grey-400 hover:shadow-lg;
            > ._icon {
                @apply w-8;
            }

            > ._name {
                @apply w-8;
            }
        }
    }

    > .form-map {
        @apply flex-1 rounded-2xl border border-grey-50 shadow-lg;
    }
}

.contact-us-form {
    @apply border-t;
    padding-top: 2rem !important;
    margin-top: 3rem !important;
    border-radius: 0 !important;
}

.request-form {
    @apply p-4 bg-white rounded-2xl;

    > ._label {

    }

    > ._note {
        @apply -mt-4 text-sm text-grey-blue-500 mb-4;
    }

    > ._warranty {
        @apply flex items-center justify-center;
        > ._item {
            @apply flex items-center;
            > ._icon {
                @apply mr-2 text-2xl text-green-500;
            }

            > ._text {
                @apply font-bold;
            }

            + ._item {
                @apply ml-4;
            }
        }
    }

    > ._btn-save {
        @apply block w-1/2 mx-auto mt-8 py-3 bg-blue-700 rounded-xl;
    }

    > ._privacy {
        @apply text-center mt-6 text-sm;
    }
}


///

@include tablet {

    .contact-us {
        @apply flex space-x-4;

        > .form-detail {
            @apply w-8/12;

            .title {
                @apply text-base font-bold text-blue-700;
            }

            ._address {
                @apply my-4 text-base;
            }

            .contact-item {
                @apply py-2 px-4 border border-grey-100 rounded-lg my-2 cursor-pointer hover:border-grey-400 hover:shadow-lg;
                > ._icon {
                    @apply w-8;
                }

                > ._name {
                    @apply text-sm;
                }
            }
        }

        > .form-map {
            @apply w-4/12 rounded-2xl border border-grey-50 shadow-lg;
        }

    }

}

@include mobile {

    ._head-menu {
        @apply hidden;
    }

    .menu-side {
        @apply hidden;
    }

    .request-form {
        @apply mt-8 p-4 shadow-lg;
    }

    .contact-us {
        @apply flex-col space-x-0;

        > .form-detail {
            @apply flex-1;

            .title {
                @apply text-lg font-bold text-blue-700;
            }

            ._address {
                @apply my-4;
            }

            .contact-item {
                @apply py-2 px-4 border border-grey-100 rounded-lg my-2 cursor-pointer hover:border-grey-400 hover:shadow-lg;
                > ._icon {
                    @apply w-8;
                }

                > ._name {
                    @apply w-8;
                }
            }
        }

        > .form-map {
            @apply flex-none mt-3 h-72;
        }
    }

    .contact-us-form {
        @apply border-t mt-8 pt-3;

        > .layout-body-header {
            @apply mb-8;
        }

        > .un-form-item + .un-form-item {
            @apply mt-6;
        }

        > ._btn-save {
            @apply mt-3;
            .un-button {
                @apply w-full py-3 bg-blue-700 hover:bg-blue-800;
            }
        }
    }


}