$width-content: 1400px;

body {
    @apply p-0 m-0 min-h-screen;
    font-family: Kanit, sans-serif;
}

.layout-container {
    @apply flex flex-col;
    min-height: 100vh;
}

.layout-header {
    @apply flex flex-col relative shadow-sm;
    > ._bottom {
        @apply flex mt-3;

        > ._left {
            @apply flex;
            > .item {
                @apply flex mr-3 items-center;

                > .text {
                    @apply cursor-pointer text-lg px-1 text-grey-500 hover:text-grey-800;
                }
            }
        }

        > ._right {
            @apply ml-auto flex items-center;

            > ._button {
                @apply p-2 text-white w-48 rounded-lg;
                background-color: #1a74bb;
                box-shadow: 2px 2px 0px #f0c765;
            }

        }

    }
}

.layout-header-mobile {
    @apply hidden;
}

.layout-header-top {
    @apply flex items-center py-4;
    @apply w-full mx-auto;
    max-width: $width-content;

    > ._logo {
        @apply mr-auto;
        > img {
            @apply h-12;
        }
    }

    > ._item {
        &.-border {
            @apply ml-4 pl-4 border-l-2 border-l-grey-100;
        }

        > ._icon {
            @apply text-xl;
        }

        > ._text {
            @apply text-lg font-bold ml-2;
            &._text:hover {
                @apply text-red-500;
            }
        }
    }
}

.layout-header-menu {
    @apply flex items-center py-3 border-t border-t-grey-100;
    @apply w-full mx-auto;
    max-width: $width-content;

    > ._item {
        @apply text-lg cursor-pointer hover:text-red-500;
        + ._item {
            @apply ml-4;
        }

        &.-active {
            @apply font-bold;
        }
    }

    > ._i18n {
        @apply ml-auto cursor-pointer hover:text-red-500;
    }

    > ._btn-submit {
        @apply ml-auto py-2 px-4 text-white rounded-lg;
        @apply bg-blue-600 shadow shadow-orange-400 hover:bg-blue-700;

        > ._icon {
            @apply mr-2;
        }
    }
}

.layout-body {
    @apply flex-1 py-6 bg-bottom bg-cover;
    background-image: url('/static/bg/body.png');

    &.-bg_star {
        background-image: url('/static/bg/body_star.png');
    }

    &.-no_grow {
        @apply flex-grow-0;
    }

    &.-flex_full {
        @apply flex;
        > ._container {
            @apply flex flex-col flex-1;
        }
    }

    > ._container {
        @apply w-full mx-auto;
        max-width: $width-content;

        &.-flex {
            @apply flex flex-row;
        }
    }
}

.layout-body-container {
    @apply w-full mx-auto;
    max-width: $width-content;
}

.layout-body-content {
    @apply flex-1 p-4 border border-grey-100 bg-white rounded-2xl;
}

.layout-body-header {
    @apply text-2xl font-bold mb-4;
}

.layout-footer {
    @apply bg-blue-700;

    > ._container {
        @apply flex py-6 w-full mx-auto;
        max-width: $width-content;

        > ._box-logo, > ._box-verify, > ._box-contact {
            @apply flex-1;
        }

        > ._box-logo {
            > ._img {
                @apply mb-4 max-w-full;
            }

            > ._text {
                @apply text-white mt-1;
                &.-sm {
                    @apply text-sm;
                }
            }
        }

        > ._box-verify, > ._box-contact {
            > ._head {
                @apply text-lg text-white;
            }
        }

        > ._box-verify {
            @apply mx-4;
            > ._item {
                @apply flex items-center mt-2;
                > ._img {
                    @apply w-20;
                }

                > ._name {
                    @apply text-sm text-white ml-4;
                }

                > ._number {
                    @apply text-sm text-white ml-1;
                }
            }
        }

        > ._box-contact {
            > ._items {
                @apply mt-2 grid grid-cols-2;
                column-gap: 1rem;
                row-gap: 1rem;

                > ._item {
                    @apply rounded-lg bg-white p-1 cursor-pointer hover:bg-blue-50;
                    > ._icon {
                        @apply w-8 text-center mr-1;
                    }
                }
            }
        }
    }
}

.layout-footer-link {
    @apply flex items-center justify-center p-2 relative text-sm text-white bg-blue-800;

    a {
        @apply cursor-pointer hover:text-blue-300;
        + a {
            @apply ml-4;
        }
    }

    > ._mobile {
        @apply hidden;
    }
}

.layout-information {
    @apply flex-1 bg-bottom bg-cover;
    background-image: url('/assets/bg/BG3@3x.png');
}

.menu-side {
    @apply w-80 p-4 mr-4 border border-grey-100 bg-white rounded-2xl;
    > ._item {
        @apply flex items-center p-4 pl-6 rounded-2xl;
        &.-active {
            @apply ring-1;
            > ._icon {
                @apply block text-blue-500;
            }
        }

        &:hover {
            @apply bg-grey-50;
        }

        + ._item {
            @apply mt-2;
        }

        > ._icon {
            @apply hidden ml-auto;
        }
    }
}

.page-error {
    @apply flex flex-col items-center m-auto;
    > ._error-code {
        @apply text-center font-bold text-blue-600;
        font-size: 14rem;
        letter-spacing: 1rem;
        text-shadow: .5rem .5rem #babdbf;

        ._yellow {
            @apply text-yellow-500;
        }
    }

    > ._head {
        @apply text-3xl font-bold;
    }

    > ._detail {
        @apply text-center mt-6;
    }

    > ._head-sm {
        @apply mt-6 text-sm;
    }

    > ._contacts {
        @apply mx-auto mt-3;
        > ._item {
            @apply flex items-center mt-2 cursor-pointer hover:text-red-500;
            > ._icon {
                @apply w-8;
            }
        }
    }

    > ._button {
        @apply mt-5 p-2 w-full bg-blue-700 text-white text-xl font-light rounded-lg;
    }

    > ._note {
        @apply mt-5 text-sm text-red-500;
    }
}
