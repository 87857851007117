.news-list {
    @apply flex;
    > ._items {
        @apply grid grid-cols-4 grid-flow-row gap-8;
    }
}

.news-list-item {
    @apply block hover:text-red-500;
    > ._photo {
        @apply block rounded-xl overflow-hidden;
        > img {
            @apply aspect-video object-cover w-full;
        }
    }

    > ._title {
        @apply block mt-4 font-bold;
    }
}

.news-item {
    @apply cursor-pointer;

    &.card {
        @apply p-3 border border-grey-100 rounded-xl;
    }

    > .title {
        @apply mt-5 text-xl;
        &.bold {
            @apply font-bold;
        }
    }

    > .tag {
        @apply mt-3 font-light text-grey-500;
    }

    img {
        @apply rounded-2xl;
    }
}

.news-container {
    @apply flex;
    > ._container {
        @apply flex-1 flex items-start;
        > .news-content {
            @apply flex-1;
        }

        > .news-side-list {
            @apply ml-6 w-3/12;
        }
    }
}

.news-content {
    @apply p-3 bg-white border border-grey-200 rounded-xl;

    > ._info {
        @apply text-sm text-grey-500 mb-4;
    }

    > ._photo {
        @apply mb-8 block rounded-xl overflow-hidden;
        > img {
            @apply aspect-video object-cover w-full;
        }
    }

    > .detail {
        @apply mt-5 font-normal text-lg text-grey-500;
    }

}

.news-side-list {
    @apply p-3 bg-white border border-grey-200 rounded-xl;
    > ._header {
        @apply text-blue-700;
    }

    > .news-list-item {
        + .news-list-item {
            @apply mt-4;
        }

        > ._photo {
            > img {
                @apply rounded aspect-video object-cover w-full;
            }
        }
    }
}


@include tablet {
    .news-list {
        @apply flex m-4;
        > ._items {
            @apply grid grid-cols-4 grid-flow-row gap-4;
        }
    }

    .news-container {
        @apply m-4;
    }
}


@include mobile {

    .news-list {
        @apply flex-none m-0;
        > ._items {
            @apply grid grid-cols-4 grid-flow-row gap-4;
        }
    }

    .news-container {
        @apply m-4;
        > ._container {
            @apply block items-start;

            > .news-side-list {
                @apply ml-0 w-full;
            }

        }
    }

    .news-side-list {
        @apply mt-3 p-3 bg-white border border-grey-200 rounded-xl;

        > ._header {
            @apply text-blue-700;
        }

        > .news-list-item {
            + .news-list-item {
                @apply mt-4;
            }

            > ._photo {
                > img {
                    height: 120px;
                }
            }
        }
    }


}