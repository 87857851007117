.home-banner {
    @apply relative overflow-hidden;
    min-height: 600px;

    > ._bg {
        @apply bg-cover bg-top;
        aspect-ratio: 400/225;
        background-image: url('/static/home/banner_bg3.jpg');
    }

    > ._container {
        @apply flex justify-between pt-8;
        > ._detail {
            @apply w-1/3 z-10;
        }
    }
}

.home-banner-form {
    @apply relative z-10 w-full p-4 bg-white rounded-2xl border border-grey-100;
    max-width: 640px;

    > ._title {
        @apply font-bold text-xl;
    }

    > ._checkbox {
        @apply mx-4 my-2 justify-between;
    }

    > ._selected-box {
        @apply flex;

        > ._input-box {
            @apply mt-3 w-full ;

            + ._input-box {
                @apply ml-3;
            }

            > ._label {
                @apply mb-1;
            }
        }
    }

    > ._btn-save {
        @apply block mx-auto w-1/2 bg-blue-700 text-white rounded-xl;
        &.-disabled {
            @apply bg-grey-100 text-grey-400;
        }
    }

    > ._hint {
        @apply text-center mt-4 text-grey-400;
    }
}

.home-container {
    @apply relative w-full mx-auto;
    max-width: $width-content;

    > ._car {
        @apply absolute w-1/3 z-0 hidden;
        &.-tr {
            max-width: 500px;
            top: 5%;
            right: -15%;
        }

        &.-bc {
            max-width: 480px;
            top: 10%;
            left: 40%;
        }
    }

    > ._detail {
        @apply hidden;
    }

    &.-push_up {
        @apply mt-14;
    }

    .layout-body-header {
        @apply text-blue-700;
    }

    .request-form {
        @apply relative z-10 w-1/2;
    }
}

.home-banner-title {
    @apply mt-5 text-8xl font-bold text-white;
    text-shadow: 8px 8px #002d64;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ddb30c;

    &.-sm {
        @apply text-7xl;
    }

    + .home-banner-detail {
        @apply mt-12;
    }
}

.home-banner-form {
    @apply mx-auto;
}

.home-banner-detail {
    @apply mt-6 text-white;
}

.home-insurance-promotion {
    @apply flex;
    > ._item {
        @apply flex-1 text-center px-20 py-10;

        > ._icon {
            @apply text-6xl text-blue-700 font-bold;
            text-shadow: 4px 4px $yellow;
        }

        > ._title {
            @apply mt-4 text-lg font-bold;
        }

        > ._detail {
            @apply mt-4 text-sm text-grey-600;
        }
    }
}

.home-compony-list {
    @apply flex flex-wrap justify-center;

    > ._item {
        @apply m-4 w-20 h-20 overflow-clip border rounded-2xl;
    }
}

.home-review-slide {
    @apply mt-12 py-8;
    background-image: linear-gradient(180deg, rgba(249, 249, 249, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.home-insurance-popular {
    @apply flex space-x-4;
}

.home-news {
    @apply flex my-8;

    > ._item {
        @apply relative rounded-2xl overflow-hidden flex-1;
        + ._item {
            @apply ml-4;
        }

        > ._photo {
            @apply overflow-hidden rounded-3xl;
            img {
                width: 100%;
                height: 300px;
                object-fit: cover;
            }
        }

        > ._title {
            @apply absolute inset-x-4 bottom-4 text-3xl text-white;
        }
    }
}

.home-news-list {
    @apply flex;
}

.home-article-list {
    @apply flex;
}

.home-feq-list {
    @apply w-2/3 mx-auto mb-8;
    //max-width: $width-content-sm;

    > ._item {
        @apply border my-3 p-3 rounded-md;
        > ._question {
            @apply flex;
            > ._text {
                @apply font-bold my-1;
            }

            > ._icon {
                @apply ml-auto;
            }
        }

        > ._answer {
            @apply text-sm mt-2;
        }
    }
}


@include tablet {
    .home-container {
        @apply w-full mx-auto pl-3;
        max-width: $width-content;

        &.-push_up {
            @apply mt-0;
        }

        .layout-body-header {
            @apply text-blue-700 mx-4;
        }

        .request-form {
            @apply absolute z-10 mt-3 flex-1 w-auto top-3/4 left-4 right-4 p-4 rounded-lg;
        }
    }

    .home-banner-form {
        @apply mr-4;
    }

    .home-insurance-promotion {
        @apply flex;
        > ._item {
            @apply flex-1 text-center px-8 py-4;

            > ._icon {
                @apply text-6xl text-blue-700 font-bold;
                text-shadow: 4px 4px $yellow;
            }

            > ._title {
                @apply mt-4 text-lg font-bold;
            }

            > ._detail {
                @apply mt-4 text-sm text-grey-600;
            }
        }
    }

    .home-insurance-popular {
        @apply m-4;
    }

    .home-news {
        @apply mx-4 flex my-8;

        > ._item {
            @apply flex-1;
            + ._item {
                @apply ml-3;
            }

            > ._photo {
                @apply overflow-hidden rounded-lg;
                img {
                    width: 100%;
                    height: 200px;
                    object-fit: cover;
                }
            }
        }
    }

    .home-article-list {
        @apply flex  m-4;
    }

    .home-news-list {
        @apply flex  m-4;
    }
}

@include mobile {

    .home-banner {
        > ._container {
            @apply flex-col py-0;
            > ._car {
                @apply hidden;
            }

            > ._detail {
                @apply w-full;
            }
        }
    }

    .home-container {
        @apply w-full mx-auto;
        max-width: $width-content;

        &.-push_up {
            @apply mt-6;
        }

        .layout-body-header {
            @apply text-blue-700;
        }

        .request-form {
            @apply relative z-10 w-1/2;
        }
    }

    .home-banner-title {
        @apply mt-4 text-5xl text-center;
        &.-sm {
            @apply text-4xl;
        }
    }

    .home-banner-detail {
        @apply mx-4 mt-6 text-white text-sm text-center font-light;
    }

    .home-banner-form {
        @apply mx-4 mt-6 w-auto;
        > ._btn-save {
            @apply w-full bg-blue-700 text-white rounded-xl;
            &.-disabled {
                @apply bg-grey-100 text-grey-400;
            }
        }

        > ._hint {
            @apply text-sm text-center;
        }
    }


    .home-insurance-promotion {
        @apply flex overflow-x-scroll m-4;

        > ._item {
            @apply flex-1 text-center p-4 rounded-lg border;

            + ._item {
                @apply ml-3;
            }

            > ._title {
                @apply w-64 mt-4 text-lg font-bold;
            }
        }
    }

    .home-compony-list {
        @apply flex flex-wrap justify-center space-x-2;

        > ._item {
            @apply w-20 h-20 overflow-clip border rounded-2xl;
        }
    }

    .home-insurance-popular {
        @apply overflow-x-auto m-4;
    }

    .home-news {
        @apply mx-4 flex my-8;

        > ._item {
            @apply flex-1;
            + ._item {
                @apply ml-3;
            }

            > ._photo {
                @apply overflow-hidden rounded-lg;
                img {
                    width: 100%;
                    height: 100px;
                    object-fit: cover;
                }
            }
        }
    }

    .home-article-list {
        @apply flex overflow-x-auto m-4;
    }

    .home-news-list {
        @apply flex overflow-x-auto m-4;
    }

    .home-feq-list {
        @apply w-full;

        > ._item {
            @apply mx-4;
        }
    }

}
