.news-card {
    @apply flex-1 p-2 rounded-md;
    + .article-card {
        @apply ml-4;
    }

    > ._photo {
        @apply block;
        img {
            @apply rounded aspect-video object-cover w-full;
        }
    }

    > ._title {
        @apply block font-bold text-lg mt-2;
    }

}

.article-card {
    @apply flex-1 border p-2 rounded-md;
    + .article-card {
        @apply ml-4;
    }

    > ._photo {
        @apply block;
        img {
            @apply rounded aspect-video object-cover w-full;
        }
    }

    > ._tag {
        @apply block my-4 text-grey-500 text-sm;
    }

    > ._title {
        @apply block font-bold;
    }

    > ._icon {
        @apply mt-4 ml-2;
    }
}

.review-slide-items {
    @apply flex;
    > ._item {
        @apply relative flex-1 p-4 rounded-md bg-white shadow;
        + ._item {
            @apply ml-4;
        }

        &::before, &::after {
            @apply absolute text-9xl text-yellow-200;
        }

        &::before {
            @apply top-0 left-4;
            content: "“";
        }

        &::after {
            @apply bottom-0 right-4 -mb-12;
            content: "”";
        }

        > ._avatar {
            @apply relative w-20 mx-auto rounded-lg;
            &::before {
                @apply absolute inset-0 top-0 left-0 rounded-3xl;
                content: "";
                box-shadow: 4px 4px 0 2px $blue;
            }

            &::after {
                @apply absolute inset-0 top-0 left-0 rounded-3xl;
                content: "";
                box-shadow: -4px -4px 0 2px #ff0;
            }

            > ._img {
                @apply rounded-3xl;
                height: 70px;
                width: 70px;
            }

            > ._icon {
                @apply flex items-center justify-center text-5xl text-grey-100;
                height: 70px;
                width: 70px;

            }
        }

        > ._title {
            @apply font-bold text-center text-grey-400 mt-6;
        }

        > ._detail {
            @apply px-8 text-center mt-6;
        }

        > ._time {
            @apply px-8 text-center text-grey-400 mt-6;
        }
    }
}

@include tablet {

    .review-slide-items {
        @apply mx-4;
    }

}

@include mobile {

    .review-slide-items {
        @apply relative flex overflow-x-scroll m-4;
        > ._item {
            @apply flex-none p-4 rounded-md shadow w-80;
        }
    }

    .article-card {
        @apply flex-none w-96 mt-0 p-2 border rounded-md;
        + .article-card {
            @apply ml-3;
        }
    }

    .news-card {
        @apply flex-none w-96 mt-0 p-2 border-none rounded-md;
        + .news-card {
            @apply ml-3;
        }
    }

}