.faq-item {
    + .faq-item {
        @apply pt-4 mt-4 border-t border-grey-100;
    }

    > ._title {
        @apply text-lg font-bold;
    }

    > ._detail {
        @apply mt-2 text-grey-500;
    }
}

.privacy-content {
    @apply text-grey-500;
}

.privacy-cookie-content {
    @apply text-grey-500;
}

@include tablet {

    .faq-item {
        + .faq-item {
            @apply pt-4 mt-4 border-t border-grey-100;
        }

        > ._title {
            @apply text-base;
        }

        > ._detail {
            @apply text-sm;
        }
    }

    .privacy-content {
        @apply text-sm;
    }

    .privacy-cookie-content {
        @apply text-sm;
    }

}

@include mobile {



}
