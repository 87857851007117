@include tablet {
    /*body {
        @apply bg-red-500;
    }*/

    .layout-body-header {
        @apply text-2xl font-bold mb-4 mx-4;
    }

    .layout-header-top {
        @apply flex items-center py-3 px-4;
        @apply w-full mx-auto;
        max-width: $width-content;

        > ._logo {
            @apply mr-auto;
            > img {
                @apply h-10;
            }
        }

        > ._item {
            &.-border {
                @apply ml-4 pl-4 border-l-2 border-l-grey-100;
            }

            > ._icon {
                @apply text-base mr-0.5;
            }

            > ._text {
                @apply text-base font-bold ml-1;
            }
        }
    }

    .layout-header-menu {
        @apply flex items-center py-3 px-4 border-t border-t-grey-100;
        @apply w-full mx-auto;
        max-width: $width-content;

        > ._item {
            @apply text-base cursor-pointer hover:text-red-500;
            + ._item {
                @apply ml-4;
            }
        }

        > ._i18n {
            @apply ml-auto;
        }

        > ._btn-submit {
            @apply ml-auto py-2 px-4 text-white text-sm rounded-lg;
            @apply bg-blue-600 shadow shadow-orange-400 hover:bg-blue-700;

            > ._icon {
                @apply mr-2;
            }
        }
    }

    .layout-footer {
        @apply bg-blue-700;
        > ._container {
            @apply flex py-6 px-4 w-full mx-auto;
            max-width: $width-content;

            > ._box-logo, > ._box-verify, > ._box-contact {
                @apply flex-1;
            }

            > ._box-logo {
                //@apply bg-red-500;
                > ._img {
                    @apply mb-4 max-w-full h-14;
                }

                > ._text {
                    @apply text-white text-xs font-light mt-1;
                    &.-sm {
                        @apply text-xs;
                    }
                }
            }

            > ._box-verify, > ._box-contact {
                > ._head {
                    @apply text-base text-white;
                }
            }

            > ._box-verify {
                @apply mx-4;
                > ._item {
                    @apply flex items-center mt-2;
                    > ._img {
                        @apply w-14;
                    }

                    > ._name {
                        @apply text-xs text-white ml-2;
                    }

                    > ._number {
                        @apply text-xs text-white ml-1;
                    }
                }
            }

            > ._box-contact {
                > ._items {
                    @apply mt-2 grid grid-cols-2;
                    column-gap: 0.5rem;
                    row-gap: 0.5rem;

                    > ._item {
                        @apply rounded-lg bg-white p-1 cursor-pointer hover:bg-blue-50 h-min;
                        > ._icon {
                            @apply w-4 text-center mr-0.5;
                        }

                        > ._name {
                            @apply ml-1 text-sm font-light;
                        }
                    }
                }
            }
        }
    }

    .layout-footer-link {
        @apply flex items-center justify-center p-2 relative text-xs font-bold text-white bg-blue-800;

        a {
            @apply cursor-pointer hover:text-blue-300;
            + a {
                @apply ml-4;
            }
        }

        > ._mobile {
            @apply hidden;
        }
    }

    .layout-body-content {
        @apply flex-1 p-4 border border-grey-100 bg-white rounded-xl mr-4;
    }

    .layout-body-header {
        @apply text-xl font-bold mb-4;
    }

    .layout-footer {
        @apply hidden;
    }

    .menu-side {
        @apply w-72 p-3 mx-4 border border-grey-100 bg-white rounded-lg;
        > ._item {
            @apply text-sm flex items-center p-2 pl-6 rounded-lg;
            &.-active {
                @apply ring-1;
                > ._icon {
                    @apply text-blue-500;
                }
            }

            &:hover {
                @apply bg-grey-50;
            }

            + ._item {
                @apply mt-2;
            }

            > ._icon {
                @apply ml-auto;
            }
        }
    }

    .page-error {
        @apply flex flex-col items-center m-auto;
        > ._error-code {
            @apply text-center font-bold text-blue-600;
            font-size: 10rem;
            letter-spacing: 1rem;
            text-shadow: .5rem .5rem #babdbf;

            ._yellow {
                @apply text-yellow-500;
            }
        }

        > ._head {
            @apply text-2xl font-bold;
        }

        > ._detail {
            @apply text-center mt-6;
        }

        > ._head-sm {
            @apply mt-6 text-sm;
        }

        > ._contacts {
            @apply mx-auto mt-3;
            > ._item {
                @apply flex items-center mt-2;
                > ._icon {
                    @apply w-8;
                }
            }
        }

        > ._button {
            @apply mt-5 py-1.5 w-full bg-blue-700 text-white text-base font-light rounded-lg;
        }

        > ._note {
            @apply mt-5 text-xs text-red-500;
        }
    }

}

@include mobile {

    .layout-header-top,

    .layout-header-menu {
        @apply hidden;
    }

    .layout-header-mobile {
        @apply block py-3 px-4;

        &.-home {
            background-color: #1a75ba;
        }

        > ._head-top {
            @apply mt-2;

            > ._menu-float {
                @apply z-20 absolute top-0 left-0 right-0 bg-white shadow-2xl rounded-b-lg;

                > ._form-top {
                    @apply mb-5 mt-12 flex items-center mx-4;

                    > ._logo {
                        @apply w-32 ml-auto;
                    }
                }

                > ._form-list {
                    @apply my-5 items-center mx-4 font-medium;

                    > ._item {
                        + ._item {
                            @apply mt-3;
                        }
                    }
                }

                > ._form-lang {
                    @apply mx-4 mb-4;
                }

            }

            > ._menu-home {
                @apply flex h-10;

                > ._logo {
                    @apply w-32 mr-auto;
                }

                > ._menu-bar {
                    @apply h-max bg-white border-none text-blue-500;
                }

                > ._button {
                    @apply ml-auto bg-white px-4 py-2 rounded-lg text-blue-500;
                    box-shadow: 2px 2px 0px #f0c765;
                }
            }

            > ._menu {
                @apply flex h-10;

                > ._logo {
                    @apply w-32 mr-auto;
                }

                > ._menu-bar {
                    @apply h-max bg-white border-none text-blue-500;
                }

                > ._button {
                    @apply ml-auto bg-blue-600 px-4 py-2 rounded-lg text-white;
                    box-shadow: 2px 2px 0px #f0c765;
                }
            }

            > ._select-contact {
                @apply w-full my-3 p-2 text-lg;
                &.-show {
                    @apply hidden
                }
            }

        }

        > ._head-menu {
            @apply w-full  my-3 p-2;
        }

    }

    .layout-body-header {
        @apply px-4 my-4 text-xl font-bold;
    }

    .layout-body-content {
        @apply m-3;
    }

    .layout-footer {
        @apply bg-blue-700;

        > ._container {
            @apply flex flex-col py-6 w-full mx-auto;
            max-width: $width-content;

            > ._box-logo, > ._box-verify, > ._box-contact {
                @apply flex-1;
            }

            > ._box-logo {
                @apply m-4 order-2;
                > ._img {
                    @apply mb-4 max-w-full;
                }

                > ._text {
                    @apply text-sm text-white mt-1;
                    &.-sm {
                        @apply text-xs;
                    }
                }
            }

            > ._box-verify, > ._box-contact {
                > ._head {
                    @apply text-lg text-white;
                }
            }

            > ._box-verify {
                @apply m-4 order-3;
                > ._item {
                    @apply flex items-center mt-2;
                    > ._img {
                        @apply w-20;
                    }

                    > ._name {
                        @apply text-sm text-white ml-4;
                    }

                    > ._number {
                        @apply text-sm text-white ml-1;
                    }
                }
            }

            > ._box-contact {
                @apply m-4 order-1;

                > ._items {
                    @apply mt-2 grid grid-cols-2;
                    column-gap: 1rem;
                    row-gap: 1rem;

                    > ._item {
                        @apply rounded-lg bg-white p-1 cursor-pointer hover:bg-blue-50;
                        > ._icon {
                            @apply w-8 text-center mr-1;
                        }
                    }
                }
            }
        }
    }

    .layout-footer-link {
        @apply flex-col items-start;
        a {
            @apply py-2;
        }

        a + a {
            @apply ml-0;
        }
    }

    .page-error {
        @apply flex flex-col items-center m-auto;
        > ._error-code {
            @apply text-center font-bold text-blue-600;
            font-size: 8rem;
            letter-spacing: 1rem;
            text-shadow: .5rem .5rem #babdbf;

            ._yellow {
                @apply text-yellow-500;
            }
        }

        > ._head {
            @apply text-xl font-bold;
        }

        > ._detail {
            @apply text-sm text-center mt-6;
        }

        > ._head-sm {
            @apply mt-6 text-sm;
        }

        > ._contacts {
            @apply text-sm mx-auto mt-3;
            > ._item {
                @apply flex items-center mt-2;
                > ._icon {
                    @apply w-8;
                }
            }
        }

        > ._button {
            @apply mt-5 py-1.5 w-full bg-blue-700 text-white text-sm font-light rounded-lg;
        }

        > ._note {
            @apply mt-5 text-xs text-red-500;
        }
    }
}