$width-insurance-box: 600px;

.insurance-card {
    @apply flex-1 p-2 border border-grey-100 rounded-xl bg-white;

    &.-full {
        > ._details, > ._prices, ._btn-select {
            @apply ml-20;
        }
    }

    > ._header {
        @apply flex items-center mb-3;
        > ._logo {
            @apply mr-4;
            > img {
                @apply h-16 w-16;
            }
        }

        > ._title {
            @apply text-lg font-bold;
            > ._name {
                @apply text-xl;
            }

            > ._type {
                @apply text-blue-500 ;
            }
        }
    }

    > ._btn-select {
        @apply flex pt-4 border-t border-grey-100;
        > ._item {
            @apply flex flex-1 items-center justify-center font-bold cursor-pointer hover:text-red-500;
            + ._item {
                @apply border-l border-grey-100;
            }

            > ._icon {
                @apply text-blue-700 text-xl mr-2;
            }
        }
    }

    > ._details {
        @apply py-2 my-4 border-y border-grey-100;
        > ._detail {
            @apply flex my-2;
            > ._name {
                @apply font-light mr-auto;
            }

            > ._value {
                @apply font-bold;
            }
        }
    }

    > ._prices {
        @apply grid grid-cols-2 grid-flow-row gap-2;

        > ._price {
            @apply border-2 py-2 border-amber-200 bg-amber-50 text-center rounded-lg;

            > ._type {
                @apply text-sm;
                > ._hl {
                    @apply text-orange-500;
                }
            }

            > ._money {
                @apply text-xl mt-2 font-bold text-blue-700;
            }

            > ._btn-submit {
                @apply text-orange-500;
            }
        }
    }

    > ._btn-detail {
        @apply mt-3 font-bold text-center cursor-pointer hover:text-red-500;
        > ._icon {
            @apply text-blue-700 font-bold mr-2;
        }
    }
}

.insurance-card-summary {
    @apply flex p-4 border border-grey-100 rounded-xl;
    > ._logo {
        @apply mr-4;
        > img {
            @apply h-24 w-24;
        }
    }

    > ._detail {
        @apply flex-1;
        > ._item {
            @apply flex my-2;
            &.-header {
                @apply pb-2 text-xl border-b border-grey-100;
                > ._name {
                    @apply font-bold;
                }

                > ._value {
                    @apply text-blue-600;
                }
            }

            &:last-child {
                @apply pb-4 border-b border-grey-100;
            }

            > ._name {
                @apply font-light mr-auto;
            }

            > ._value {
                @apply font-bold;
            }
        }
    }
}

.insurance-interest {
    @apply p-4 border border-grey-100 rounded-lg shadow-lg bg-white mx-auto max-w-full;
    width: $width-insurance-box;

    &.-home {
        @apply relative z-10;
    }

    > ._head {
        > ._top {
            @apply flex;
            > ._reset {
                @apply cursor-pointer hover:text-red-500;
                > .un-icon {
                    @apply mr-2;
                }
            }

            > ._page {
                @apply ml-auto text-lg text-right text-blue-500 font-bold;
            }
        }

        > ._steps {
            @apply flex my-4;
            > ._step {
                @apply flex-1 h-2 bg-blue-100 rounded-full cursor-pointer;
                + ._step {
                    @apply ml-2;
                }

                &.-has {
                    @apply bg-blue-300;
                }

                &.-active {
                    @apply bg-blue-700;
                }

                &:hover {
                    @apply bg-red-500;
                }
            }
        }
    }

    > ._title {
        > ._icon {
            @apply text-5xl font-bold text-blue-500;
            text-shadow: 4px 4px $yellow;
        }

        > ._text {
            @apply my-4 text-3xl font-bold;
        }
    }

    > ._selector {
        @apply flex flex-col;

        > ._wait-option {
            @apply static flex items-center justify-center m-0 h-20 w-full;
            > .un-icon {
                @apply text-5xl;
            }
        }

        > ._btn-next {
            @apply mt-4 ml-auto;
        }
    }

    > ._button {
        @apply flex mt-5 w-64 mx-auto;
    }
}

.insurance-interest-customer {
    @apply py-4;

    > ._privacy {
        @apply text-center text-xs my-5;
    }

    > ._button {
        @apply flex mx-auto;
    }
}

.insurance-interest-item_select {
    > ._item {
        @apply py-2 px-4 w-full border rounded-lg text-xl text-grey-600 cursor-pointer;

        &.-active {
            @apply border-blue-500 bg-blue-50 text-blue-800;
        }

        &:hover {
            @apply border-orange-500 bg-orange-50 text-black;
        }

        + ._item {
            @apply mt-3;
        }
    }
}

.insurance-interest-input_select {
    @apply p-2 w-full border rounded-lg text-base h-10;
}

.insurance-search-container {
    @apply flex;
    > ._container {
        @apply flex flex-1;
        > .insurance-search-filter {
            @apply w-1/4;
        }
    }
}

.insurance-search-filter {
    @apply p-3 h-max rounded-xl bg-white shadow-lg;

    > .title {
        @apply text-xl text-blue-500 font-bold;
    }

    > .title-mini {
        @apply text-lg text-blue-500 font-bold;
    }
}

.insurance-search-filter-item {
    > ._label {
        @apply text-blue-600 font-bold;
    }

    > ._control {
        > .un-input-checkbox {
            > .un-input-checkbox-item {
                @apply border rounded-md py-2 pl-10 mb-2 cursor-pointer hover:bg-blue-50 hover:border-blue-500;
            }
        }
    }
}

.insurance-search-body {
    @apply flex flex-col flex-1 ml-8;
}

.insurance-search-items-container {
    @apply flex flex-col flex-1;
}

.insurance-search-items {
    @apply grid grid-cols-2 grid-flow-row gap-4;
}

.insurance-search-paging {
    @apply justify-center;
}

.insurance-search-compare {
    box-shadow: 0px -4px 8px 4px rgba(0, 0, 0, 0.1);

    > ._content {
        @apply flex;

        > ._selected {
            @apply flex items-center py-4 border-r border-grey-100;
            > ._logo {
                @apply w-16 h-16;
            }

            > ._info {
                @apply ml-2;
                > ._name {
                    @apply text-lg;
                }

                > ._year {
                    @apply text-grey-500;
                }
            }

            > ._btn-edit {
                @apply mx-6 text-amber-500 cursor-pointer;
            }
        }

        > ._compares {
            @apply flex flex-col flex-1 py-1 px-3 ;
            > ._head {
                @apply font-bold;
            }

            > ._content {
                @apply flex flex-1;

                > ._item {
                    @apply flex items-center py-2 border-r border-grey-100;
                    > ._logo {
                        @apply w-12 h-12;
                    }

                    > ._detail {
                        @apply ml-2;
                        > ._company {
                            @apply text-sm;
                        }

                        > ._money {
                            @apply font-bold text-blue-600;
                        }
                    }

                    > ._btn-delete {
                        @apply mb-auto mx-2 text-red-500 cursor-pointer hover:font-bold;
                    }
                }

                > ._btn-submit {
                    @apply self-center ml-4;
                }

                > ._empty {
                    @apply self-center mx-auto text-grey-blue-500;
                }
            }
        }
    }
}

.insurance-detail-container {
    @apply flex;
    > ._container {
        @apply flex items-start flex-1;
        > .insurance-card {
            @apply w-1/4 mr-6;
        }

        > ._table {
            @apply flex-1;
        }
    }
}

.insurance-detail-table {
    @apply bg-white border-2 rounded-lg mb-3 overflow-hidden;

    > ._title {
        @apply p-2 text-center font-bold bg-grey-blue-50;
    }

    > ._item {
        @apply flex border-t;

        &.-btn {
            @apply border-t-0;
        }

        > ._title {
            @apply p-2 flex-1 flex items-center justify-start;
        }

        > ._value {
            @apply p-2 flex-1 flex flex-col items-center justify-center border-l;

            > ._logo {
                @apply p-1 border rounded-lg h-20 w-20 overflow-hidden mb-2;
            }

            .un-button {
                min-width: 200px;

                > ._icon {
                    @apply font-bold;
                }
            }
        }
    }

}

.insurance-summary {
    @apply flex-1 bg-grey-blue-50;
}

.insurance-summary-box {
    @apply my-8 mx-auto p-5 bg-white rounded-xl;
    width: $width-insurance-box;

    > ._header {
        @apply text-center text-blue-700;
    }
}

.insurance-summary-form {
    @apply mt-8;

    > ._warranty {
        @apply flex items-center justify-center;
        > ._item {
            @apply flex items-center;
            > ._icon {
                @apply mr-2 text-2xl text-green-500;
            }

            > ._text {
                @apply font-bold;
            }

            + ._item {
                @apply ml-4;
            }
        }
    }
}


@include tablet {

    .insurance-interest {
        > ._title {
            > ._icon {
                @apply text-3xl;
            }

            > ._text {
                @apply my-3 text-2xl;
            }
        }
    }

    .insurance-interest-customer {
        @apply py-4;

        > ._privacy {
            @apply text-center text-xs my-5;
        }

        > ._button {
            @apply flex mx-auto text-sm;
        }
    }

    .insurance-card {
        @apply p-2 border border-grey-100 rounded-xl;

        &.-full {
            > ._details, > ._prices, ._btn-select {
                @apply ml-20;
            }
        }

        > ._header {
            @apply flex items-center mb-3;
            > ._logo {
                @apply mr-4;
                > img {
                    @apply h-16 w-16;
                }
            }

            > ._title {
                @apply text-sm font-bold;
                > ._name {
                    @apply text-lg;
                }

                > ._type {
                    @apply text-blue-500 ;
                }
            }
        }

        > ._btn-select {
            @apply flex pt-4 border-t border-grey-100;
            > ._item {
                @apply flex flex-1 items-center justify-center font-bold cursor-pointer hover:text-red-500;
                + ._item {
                    @apply border-l border-grey-100;
                }

                > ._icon {
                    @apply text-blue-700 text-xl mr-2;
                }
            }
        }

        > ._details {
            @apply py-2 my-4 border-y border-grey-100;
            > ._detail {
                @apply flex my-2 text-sm ;
                > ._name {
                    @apply font-light mr-auto;
                }

                > ._value {
                    @apply font-bold;
                }
            }
        }

        > ._prices {
            @apply grid grid-cols-2 grid-flow-row gap-2;

            > ._price {
                @apply border-2 py-2 border-amber-200 bg-amber-50 text-center rounded-lg;

                > ._type {
                    @apply text-sm;
                    > ._hl {
                        @apply text-orange-500;
                    }
                }

                > ._money {
                    @apply text-base mt-2 font-bold text-blue-700;
                }

                > ._btn-submit {
                    @apply text-orange-500;
                }
            }
        }

        > ._btn-detail {
            @apply mt-3 font-bold text-center cursor-pointer hover:text-red-500;
            > ._icon {
                @apply text-blue-700 font-bold mr-2;
            }
        }
    }

}

@include mobile {
    .insurance-interest {
        @apply mx-4;

        > ._title {
            > ._icon {
                @apply text-3xl;
            }

            > ._text {
                @apply my-3 text-xl;
            }
        }
    }

    .insurance-interest-customer {
        @apply py-4;

        > ._privacy {
            @apply text-center text-xs my-5;
        }

        > ._button {
            @apply flex mx-auto text-sm;
        }
    }

    .insurance-summary-box {
        @apply w-auto mx-4;
    }

    .insurance-card-summary {

        > ._logo {
            > img {
                @apply h-16 w-16;
            }
        }

        > ._detail {
            @apply flex-1;
            > ._item {
                @apply flex my-2;
                &.-header {
                    @apply pb-2 text-xl border-b border-grey-100;
                    > ._name {
                        @apply font-bold;
                    }

                    > ._value {
                        @apply text-blue-600;
                    }
                }

                &:last-child {
                    @apply pb-4 border-b border-grey-100;
                }

                > ._name {
                    @apply font-light mr-auto;
                }

                > ._value {
                    @apply font-bold;
                }
            }
        }

    }

    .insurance-card {
        @apply flex-none;
    }

}
